<template>
    <v-card elevation="12">
        <v-toolbar color="#365d84" flat dark style="max-height:64px;">
            <v-toolbar-title>Слушатели</v-toolbar-title>
            <v-spacer></v-spacer>
            <studentEditBtn :groupId="groupId" :student="{}" @addStudent="students.push($event)"/>
                    <v-btn @click="$emit('close')" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-text-field class="ma-3" v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details>
        </v-text-field>
        <v-data-table :items="students" :headers="headers" :search="search" :loading="loading"
            :custom-filter="searchFilter" class="border" loading-text="Загрузка... Пожалуйста подождите"
            no-data-text="Результатов нет" no-results-text="Результатов нет" :footer-props="{
                'items-per-page-text': 'Количество',
                'items-per-page-options': [5, 10, 20],
            }">
            <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} из {{ itemsLength }}
            </template>
            <template v-slot:item.code="{ item }">
                <div class="text-left">
                    <v-chip class="mx-1">{{ item.code }}</v-chip>
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="text-left font-weight-medium py-0 pl-0 pr-3">
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <studentEditBtn :groupId="groupId" :student="item" @updateStudent="$set(students,students.findIndex(el=>el.id==item.id),$event)"/>
              <deleteBtn title="Удалить"  :text="'При удалении слушателя вся информация о нем будет безвозвартано потеряна, вы действительно хотите удалить слушателя: '+ item.name" :actionHref="'study/student-delete?studentId='+item.id" @remove="students.splice(students.findIndex(el=>el.id===item.id),1)"/>
            </div>
            </template>

            
            <template v-slot:no-data> Нет элементов </template>
            <template v-slot:loading>
                <v-skeleton-loader class="mx-auto" type="list-item@3"></v-skeleton-loader>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import studentEditBtn from './studentEditBtn.vue'
import deleteBtn from '../../delete.vue'
export default {
    props: ['groupId'],
    data: () => ({
        students: [],
        count: 0,
        loading: true,
        search: "",
        headers: [
            { text: "ФИО", value: "name" },
            { text: "Номер зачётки", value: "code" },
            { text: "Дата рождения", value: "birthday" },
            { text: "Код доступа", value: "pin" },
            { text: "Действия", value: "actions" },
        ],
    }),
    components:{
        studentEditBtn,
        deleteBtn
    },
    watch: {
        groupId(val) {

            if (val && val > 0) {
                this.fetchData()
            }
        }
    },
    methods: {
        searchFilter(value, search, item) {
            return (
                item.name
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1

            );
        },
        fetchData() {
            this.loading = true
            this.$http.get('study/students-list?groupId=' + this.groupId).then((response) => {
                this.students = response.data
            }).catch(() => {
                alert('Ошибка!')
            }).finally(() => {
                this.loading = false
            })
        },
    },
    mounted(){
        this.fetchData()
    }
}
</script>

<style>
</style>