<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-if="form.id" v-bind="attrs" v-on="on">
        <v-icon color="primary" v-if="form.id">mdi-circle-edit-outline</v-icon>

      </v-btn>
      <v-btn v-else v-bind="attrs" v-on="on" color="primary" class="ma-3">
        <v-icon>mdi-plus</v-icon>

      </v-btn>

              
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2 mt-3 justify-space-between">
        <div v-if="form.id">Изменить</div>
        <div v-else>Создать</div>
      </v-card-title>
      <v-card-text class="text-subtitle-1 font-weight-bold text-center pt-3">
        <v-text-field
          v-model="form.name"
          :error="errors.name ? true : false"
          :error-messages="errors.name ? errors.name : []"
          outlined
          :counter="255"
          :rules="rules.required"
          label="ФИО"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.code"
          :error="errors.code ? true : false"
          :error-messages="errors.code ? errors.code : []"
          outlined
          :counter="255"
          :rules="rules.required"
          label="Номер зачётки"
          required
        ></v-text-field>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.birthday"
              :error="errors.birthday ? true : false"
              :error-messages="errors.birthday ? errors.birthday : []"
              label="Дата рождения"
              prepend-icon="mdi-calendar"
              :rules="rules.required"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.birthday"
            locale="ru-RU"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false" :disabled="saving">
          Отменить
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save()" :loading="saving">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["student", "groupId"],
  data: () => ({
    dialog: false,
    form: {},
    errors: {},
    saving: false,
    menu:false,
    rules: {
      required: [(v) => !!v || "Не должно быть пустым"],
    },
  }),
  methods: {
    save() {
      this.saving = true;
      if (!this.form.id) {
        this.$http
          .post("study/student-add?groupId=" + this.groupId, this.form)
          .then((response) => {
            this.dialog = false;
            this.$emit("addStudent", response.data);
          })
          .catch(() => {
            alert("Ошибка!");
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.$http
          .post("study/student-update?studentId=" + this.form.id, this.form)
          .then((response) => {
            this.dialog = false;
            this.$emit("updateStudent", response.data);
          })
          .catch(() => {
            alert("Ошибка!");
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
  mounted() {
    this.form = { ...this.student };
  },
};
</script>

<style>
</style>