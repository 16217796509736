<template>
  <v-card elevation="12">
    <v-toolbar color="#365d84" flat dark>
      <v-toolbar-title>Проверки</v-toolbar-title><v-spacer></v-spacer>
      <edit
      :eduProgId="eduProgId"
        :revision="{}"
        btn
        @create="revisions.push($event)"
        :groups="groups"
        :qualifications="qualifications"
      />
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!loading">
      <v-list>
        <v-list-item
          two-line
          v-for="revision in revisions"
          :key="revision.id"
          style="min-height: unset !important"
        >
          <v-list-item-content>
            <div class="font-weight-medium py-0 pl-0 pr-3">
              Проверка "{{ revision.name }}"<br> с {{ revision.startDate }}<br> по {{ revision.endDate  }}<br>
              Группа {{ revision.group.name }}
            </div>
            <v-chip
            v-if="revision.type=='qualification'"
              color="green"
              small
              dark
              style="max-width: max-content"
              >Проверка по квалификации</v-chip>
            <v-chip
            v-if="revision.type=='disciplines'"
              color="green"
              small
              dark
              style="max-width: max-content"
              >Проверка по дисциплинам</v-chip>

          </v-list-item-content>
          <v-list-item-action>
            <div class="text-center">
                    <v-btn color="primary" class="ml-3" dark icon @click="viewId=revision.id; $refs.viewModal.dialog=true">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
              <edit
              :eduProgId="eduProgId"
                :revision="revision"
                :groups="groups"
                :qualifications="qualifications"
                @update="$set(revisions,revisions.findIndex(el=>el.id===revision.id),$event)"
                btn
              />
              <deleteBtn title="Удалить"  :text="'При удалении проверки вся информация о ней будет безвозвартано потеряна, вы действительно хотите удалить проверку: '+ revision.name" :actionHref="'revision/delete?revisionId='+revision.id" @remove="revisions.splice(revisions.findIndex(el=>el.id===revision.id),1)"/>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <revisionView ref="viewModal" :revisionId="viewId"/>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader type="list-item-two-line@1"> </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>


<script>
import revisionView from './view.vue'
import edit from "./edit.vue";
import deleteBtn from '../../delete.vue'
export default {
  name: "StarterPage",
  props: ["eduProgId", "groups"],
  data: () => ({
    revisions: [],
    qualifications: [],
    loading: true,
    viewId:null
  }),
  components: {
    deleteBtn,
    edit,
    revisionView
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("revision/list?eduProgId=" + this.eduProgId)
        .then((response) => {
          this.revisions = response.data.revisions;
          this.qualifications = response.data.qualifications;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>