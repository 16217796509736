<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" class="ml-3" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-school</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title>Формируемые компетенции</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="saving" @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-toolbar color="#365d84" flat dark style="max-height: 64px">
                Полностью сформированные компетенции к концу периода обучения
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card-text> </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-toolbar color="#365d84" flat dark style="max-height: 64px">
                Частично сформированные компетенции к концу периода обучения
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card-text> </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-toolbar color="#365d84" flat dark style="max-height: 64px">
                Не сформированные компетенции к концу периода обучения
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card-text> </v-card-text> </v-card
          ></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
<script>
export default {
  props: ["eduProgId"],
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  components: {},
  methods: {
    fetchData() {
      this.$http
        .get("competencies/load-edit-data", {
          params: {
            eduProgId: this.eduProgId,
          },
        })
        .then((response) => {
          this.programmCompet = response.data.programmCompet;
          this.fgosCompet = response.data.fgosCompet;
          this.fgosCompetList = this.fgosCompet.filter(
            (el) =>
              this.programmCompet.findIndex((pEl) => el.id === pEl.id) === -1
          );
        });
    },
  },
  mounted() {
    //this.fetchData();
  },
};
</script>

<style>
</style>