<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="800"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="color"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{text}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          РЕКОМЕНДУЕТСЯ
        </v-card-title>

        <v-card-text>
            <div class="text-h6 ma-3">
                Повысить  уровень  теоретических  знаний: </div>
            <v-simple-table>
                <tbody>
                    <tr v-for="(rec,index) in recommendations" :key="index">
                        <td>
                            {{rec}}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props:['recommendations','color','text'],
    computed:{
    },
    data () {
        
      return {
        dialog: false,
      }
    },
  }
</script>