<template>
  <v-card elevation="12">
    
    <v-toolbar color="#365d84" flat dark>
      <v-toolbar-title>Учебные группы</v-toolbar-title><v-spacer></v-spacer>
      <editBtn :group="{}" :eduProgId="eduProgId" :periods="periods" @addGroup="groups.push($event)"/>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!loading">
      <v-list v-for="(period,index) in groupList" :key="index">
        <div class="text-h6 font-weight-bold">Период {{ index }}</div>
        <v-list-item
          two-line
          style="min-height: unset !important"
          v-for="group in period"
          :key="group.id"
        >
          <template></template>
          <v-list-item-content>
            <div class="font-weight-medium py-0 pl-0 pr-3 text-left">
              {{ group.name }}
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <div class="text-center">
              <knowledges :groupId="group.id"/>
              <v-btn icon @click="curGroup=group; dialog=true" color="primary"><v-icon>mdi-account-group</v-icon></v-btn>
              <editBtn :group="group" :eduProgId="eduProgId" :periods="periods" @updateGroup="$set(groups,groups.findIndex(el=>el.id===group.id),$event)"/>
              <deleteBtn title="Удалить"  :text="'При удалении учебной группы вся информация о ней будет безвозвартано потеряна, вы действительно хотите удалить группу: '+ group.name" :actionHref="'study/group-delete?groupId='+group.id" @remove="groups.splice(groups.findIndex(el=>el.id===group.id),1)"/>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader type="list-item-two-line@1"> </v-skeleton-loader>
    </v-card-text>

      <v-dialog v-model="dialog" scrollable width="800">
        <studentList v-if="curGroup.id" :groupId="curGroup.id" @close="dialog=false"/>
    </v-dialog>
  </v-card>
</template>

<script>
import deleteBtn from '../../delete.vue'
import editBtn from './editBtn.vue'
import studentList from './studentList.vue'
import knowledges from './knowledges.vue';
var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export default {
  name: "StarterPage",
  props: ["eduProgId",'periods'],
  data: () => ({
    groups: [],
    loading: true,
    curGroup:{},
    dialog:false
  }),
  components: {
    editBtn,
    deleteBtn,
    studentList,
    knowledges
  },
  computed:{
    groupList(){
      let list=this.groups.map(function(el){
        let out={}
        out.course=el.studyPeriod.id
        out.order=el.studyPeriod.order
        out.id=el.id
        out.name=el.name
        out.note=el.note
        out.studyPeriodId=el.studyPeriodId
        return out
      })
      list=groupBy(list,'order')
      console.log(list)
      return list
    }
  },
    watch:{
    groups(){
      this.$emit('groupsUpdate',this.groups)
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http
        .get("study/groups-list?eduProgId=" + this.eduProgId)
        .then((response) => {
          this.groups = response.data.groups;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>