<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="ml-3"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!revision || !revision.id"
      >
        <v-icon>mdi-plus</v-icon></v-btn
      >
      <v-btn color="primary" :icon="!btn" dark v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-circle-edit-outline</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-toolbar color="#365d84" flat dark>
        <v-toolbar-title
          ><span v-if="!revision || !revision.id">Новая проверка</span
          ><span v-else>Изменить</span></v-toolbar-title
        ><v-spacer></v-spacer>

        <v-btn :disabled="saving" @click="dialog = false" light
          ><v-icon>mdi-close</v-icon> Отменить</v-btn
        ><v-btn :loading="saving" color="green" class="mx-3" @click="save()"
          ><v-icon>mdi-check</v-icon> Сохранить</v-btn
        >
      </v-toolbar>

      <v-card-text class="pt-3">
        <v-text-field
          v-model="form.name"
          :error="errors.name ? true : false"
          :error-messages="errors.name ? errors.name : []"
          outlined
          :counter="255"
          :rules="rules.required"
          label="Название"
          required
        ></v-text-field>
        <v-select
        v-if="!form.id"
          :items="groups"
          v-model="form.groupId"
          :error="errors.groupId ? true : false"
          :error-messages="errors.groupId ? errors.groupId : []"
          outlined
          item-text="name"
          item-value="id"
          label="Группа слушателей"
        ></v-select>
        <v-select
          v-if="!form.id"
          :items="[
            {
              label: 'Проверка по квалификации',
              value: 'qualification',
            },
            {
              label: 'Проверка по выбранным дисциплинам',
              value: 'disciplines',
            },
          ]"
          v-model="form.type"
          :error="errors.type ? true : false"
          :error-messages="errors.type ? errors.type : []"
          outlined
          item-text="label"
          item-value="value"
          label="Тип проверки"
        ></v-select>
        <v-select
          v-if="form.type == 'qualification' && !form.id"
          :items="qualifications"
          v-model="form.qualificationId"
          :error="errors.qualificationId ? true : false"
          :error-messages="errors.qualificationId ? errors.qualificationId : []"
          outlined
          item-text="name"
          item-value="id"
          label="Квалификация"
        ></v-select>
        <v-autocomplete
          v-if="form.type == 'disciplines' && form.groupId"
          :items="disciplines"
          :loading="disciplinesLoading"
          hide-no-data
          multiple
          chips
          item-text="name"
          item-value="disciplineId"
          v-model="form.disciplines"
          :error="errors.disciplines ? true : false"
          :error-messages="errors.disciplines ? errors.disciplines : []"
          label="Дисциплины"
        >
          <template v-slot:selection="data">
            <v-sheet outlined rounded class=" ma-1 pa-2">{{ data.item.discipline.name }}</v-sheet>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.discipline.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.discipline.code"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.startDate"
              :error="errors.startDate ? true : false"
              :error-messages="errors.startDate ? errors.startDate : []"
              label="Дата начала"
              prepend-icon="mdi-calendar"
              :rules="rules.required"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.startDate"
            locale="ru-RU"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.endDate"
              :error="errors.endDate ? true : false"
              :error-messages="errors.endDate ? errors.endDate : []"
              label="Дата окончания"
              prepend-icon="mdi-calendar"
              :rules="rules.required"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.endDate"
            locale="ru-RU"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["revision", "btn", "groups", "qualifications",'eduProgId'],
  data() {
    return {
      menu: false,
      menu2: false,
      disciplines: [],
      disciplinesLoading: false,
      dialog: false,
      saving: false,
      form: {
        groupId: null,
      },
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
      errors: {},
    };
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.form = { ...this.revision };
      }
    },
    "form.groupId"(val) {
      if (!val) {
        return;
      }
      // Items have already been requested
      this.disciplines = [];
      this.disciplinesLoading = true;

      this.$http
        .get("disciplines/list-by-student-group", {
          params: {
            groupId: val,
          },
        })
        .then((response) => {
          this.disciplines = response.data;
        })
        .catch(() => {
          alert("Ошибка при загрзке списка дисциплин");
        })
        .finally(() => {
          this.disciplinesLoading = false;
        });
    },
  },
  methods: {
    save() {
      this.saving = true;
      if (!this.form.id) {
        this.$http
          .post("revision/create?eduProgId="+this.eduProgId, this.form)
          .then((response) => {
            this.$emit("create", response.data);
            this.dialog=false
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              alert("Возникла непредвиденная ошибка");
            }
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.$http
          .post("revision/update?eduProgId="+this.eduProgId + "&revisionId=" + this.form.id, this.form)
          .then((response) => {
            this.$emit("update", response.data);
            this.dialog=false
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              alert("Возникла непредвиденная ошибка");
            }
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>