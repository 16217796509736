<template>
  <div v-if="eduProg">
        <studyGroups
      :eduProgId="eduProg.id"
      :periods="periods"
      @groupsUpdate="$emit('groupsUpdate',$event)"
      v-if="eduProg && eduProg.id"
      class="mt-3"
    />
    <revisions
      :eduProgId="eduProg.id"
      :groups="groups"
      v-if="eduProg && eduProg.id"
      class="mt-3"
    />

  </div>
</template>

<script>
import revisions from "./revisions/list.vue";
import studyGroups from "./studyGroups/list";
export default {
  props: ["eduProg"],
  components: {
    revisions,
    studyGroups,
  },
};
</script>

<style>
</style>