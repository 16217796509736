<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary" v-if="form.id">mdi-circle-edit-outline</v-icon>
                <v-icon color="primary" v-else>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5 red lighten-2 white--text">
                <div v-if="form.id">Изменить</div>
                <div v-else>Создать</div>
            </v-card-title>
            <v-card-text class="text-subtitle-1 font-weight-bold text-center pt-3">
                <v-text-field v-model="form.name" :error="errors.name ? true : false" :error-messages="
                    errors.name ? errors.name : []
                " outlined :counter="255" :rules="rules.required" label="Номер группы" required></v-text-field>
                <v-autocomplete v-model="form.studyPeriodId" :items="periods" color="blue-grey lighten-2"
                    label="Период обучения" item-text="order" item-value="id">
                    <template v-slot:selection="data">
                        <b class="pl-1">Курс {{ data.item.course }}</b>. Период {{ data.item.order }}.
                    </template>
                    <template v-slot:item="data">
                        <b class="pl-1">Курс {{ data.item.course }}</b>. Период {{ data.item.order }}.
                    </template>
                </v-autocomplete>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn color="primary" text @click="dialog = false" :disabled="saving"> Отменить </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="save()" :loading="saving"> Сохранить </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['group', 'eduProgId', 'periods'],
    data: () => ({
        dialog: false,
        form: {},
        errors: {},
        saving: false,
        rules: {
            required: [(v) => !!v || "Не должно быть пустым"],
        },
    }),
    methods: {
        save() {
            this.saving = true
            if (!this.form.id) {
                this.$http.post('study/group-add?eduProgId=' + this.eduProgId,
                    this.form).then((response) => {
                        this.dialog = false
                        this.$emit('addGroup', response.data)
                    }).catch(() => {
                        alert('Ошибка!')
                    }).finally(() => {
                        this.saving = false
                    })
            } else {
                this.$http.post('study/group-update?groupId=' + this.form.id,
                    this.form).then((response) => {
                        this.dialog = false
                        this.$emit('updateGroup', response.data)
                    }).catch(() => {
                        alert('Ошибка!')
                    }).finally(() => {
                        this.saving = false
                    })
            }

        }
    },
    mounted() {
        this.form = { ...this.group }
    }
}
</script>

<style>
</style>