<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title
          >Проверка
          <span v-if="revision && revision.group"
            >группы {{ revision.group.name }}</span
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn :disabled="saving" @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col cols="6">
            <v-card elevation="12">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Слушатели</v-toolbar-title
                ><v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>ФИО</th>
                      <th>Код доступа</th>
                      <th>Правильно / Всего</th>
                      <th>Набрано баллов</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="student in students" :key="student.id">
                      <td>
                        {{ student.name }}
                      </td>
                      <td>
                        {{ student.pin }}
                      </td>

                      <td>
                        <div v-if="!student.status || student.status == 0"> - </div>
                        <div v-else>{{ student.rightCount }} / {{ student.count }}</div></td>
                      <td>
                        <v-chip v-if="!student.status || student.status == 0">Не пройдено</v-chip>
                        <v-chip v-if="student.status == 1" color="orange" dark
                          >В процессе</v-chip
                        >
                        <recView
                          v-if="student.status == 2"
                          :recommendations="student.remarks"
                          :color="
                            student.balls >= qualification.min_ball
                              ? 'green'
                              : 'red'
                          "
                          :text="student.balls"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card elevation="12">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Групповой результат</v-toolbar-title
                ><v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-h6 ma-3" v-if="filteredRecs && filteredRecs.length>0">
                  Рекомендуется повысить уровень теоретических знаний:
                </div>
                 <div class="text-h6 ma-3" v-else>
                  Недостаточно данных
                 </div>
                <v-simple-table>
                  <tbody>
                    <tr v-for="(rec, index) in filteredRecs" :key="index">
                      <td>{{ rec.name }} ({{ rec.count }})</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col cols="6">
            <v-sheet elevation="12" class="pa-7" rounded>
              <v-skeleton-loader type="list-item@6"></v-skeleton-loader>
            </v-sheet>
          </v-col>
          <v-col cols="6">
            <v-sheet elevation="12" class="pa-7" rounded>
              <v-skeleton-loader type="list-item@6"></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import recView from "./recView.vue";
export default {
  props: ["revisionId"],
  data() {
    return {
      dialog: false,
      saving: false,
      loading: true,
      revision: {},
      students: [],
      qualification: {},
      groupRemarks: [],
    };
  },
  computed: {
    filteredRecs() {
      if (!this.groupRemarks || this.groupRemarks.length == 0) {
        return [];
      }
      let recs = this.groupRemarks.filter(
        function (item, pos) {
          return this.groupRemarks.indexOf(item) == pos;
        }.bind(this)
      );
      let filterdRecs = [];
      for (let rec of recs) {
        filterdRecs.push({
          name: rec,
          count: this.groupRemarks.filter((el) => el == rec).length,
        });
      }
      return filterdRecs;
    },
  },
  components: {
    recView,
  },
  methods: {
    fetchData() {},
    userExam(userId) {
      return this.revision.exams.find((el) => el.student_id === userId);
    },
  },
  watch: {
    revisionId(val) {
      if (!val) return;
      this.loading = true;
      this.$http
        .get("revision/view", {
          params: {
            revisionId: this.revisionId,
          },
        })
        .then((response) => {
          this.revision = response.data.revision;
          this.students = response.data.students;
          this.qualification = response.data.qualification;
          this.groupRemarks = response.data.groupRemarks;
        })
        .catch(() => {
          alert("Ошибка при загрзке списка слушателей");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
</style>